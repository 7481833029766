class DateTimeFormatter {

    static dateFormat(dateString, lang /* useTranslation kann hier nicht in der Klasse verwendet werden, geht nur in Components */  ) {
        const date = new Date(dateString);

        let formattedDate;
        if (lang.includes("de")) {
            // German date format: "So., 14. Jan 2024, 20:15"
            const weekdayFormatter = new Intl.DateTimeFormat('de', { weekday: 'short' });
            const dayFormatter = new Intl.DateTimeFormat('de', { day: '2-digit' });
            const monthFormatter = new Intl.DateTimeFormat('de', { month: 'short' });
            const yearFormatter = new Intl.DateTimeFormat('de', { year: 'numeric' });
    
            formattedDate = `${weekdayFormatter.format(date)}, ${dayFormatter.format(date)}. ${monthFormatter.format(date)} ${yearFormatter.format(date)}`;
        } else {
            // English date format: "Sun, Jan 14, 2024, 20:15"
            const formatter = new Intl.DateTimeFormat('en', {
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                year: 'numeric'
            });

            formattedDate = formatter.format(date);
        }
        return formattedDate;
    }

    static timeFormat(dateString, lang) {
        const date = new Date(dateString);

        let formattedTime;

        if (lang.includes("de")) {
            // German date format: "So., 14. Jan 2024, 20:15"
            const timeFormatter = new Intl.DateTimeFormat('de', { hour: 'numeric', minute: '2-digit', hour12: false });
            formattedTime = `Um ${timeFormatter.format(date)} Uhr`;
        } else {
            // English date format: "Sun, Jan 14, 2024, 20:15"
            const formatter = new Intl.DateTimeFormat('en', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
                hourCycle: 'h12'
            });
            formattedTime = "at " + formatter.format(date) + " h";
        }
        return formattedTime
    }
  }
  
  export default DateTimeFormatter;
  