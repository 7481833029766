import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clubLookup : {},
    leagueLookup : {},
    matchLookup : {},
    leaguesViewDataStructure : {},
    favorites : {}, 
}
const dataSlice = createSlice({
    name : "data",
    initialState : initialState,
    reducers : {
        setClubLookup( state, action ) {
            // object is create in JsonParser.js
            state.clubLookup = action.payload
        },

        setLeagueLookup(state, action) {
            // object is create in JsonParser.js
            state.leagueLookup = action.payload
        },

        setMatchLookup(state, action) {
            state.matchLookup = action.payload
        },

        setLeaguesViewDataStructure(state, action) {
            state.leaguesViewDataStructure = action.payload
        },

        setFavorites( state, action) {
            state.favorites = action.payload
            localStorage.setItem('favorites', JSON.stringify(action.payload))
        }
    }
})

export const dataActions = dataSlice.actions
export default dataSlice