import { Fragment } from "react"

const BookieRow = () => {

    return (<Fragment>
        <div className="d-flex w-100 justify-content-center align-items-center p-0 m-0" style= { {"border" : "1px solid red" } }>
            <h1 className="text-center p-0 m-0">...Okay...</h1>
        </div>
    </Fragment>)
}

export default BookieRow