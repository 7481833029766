import { Fragment } from "react"
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import "../css/styles.css"

const ChangeLanguage = () => {

    const { t, i18n } = useTranslation();

    const onChangeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (<Fragment>
        <div className="language_switcher_div d-flex flex-column">
            <p className="white_09_with_shadow p-0 m-0">{t("language_switcher_js__change_language")}</p>
            <Button className="language_switcher_button" onClick={() => onChangeLanguage("en")}>🇬🇧</Button>
            <Button className="language_switcher_button" onClick={() => onChangeLanguage("de")}>🇩🇪</Button>
        </div>
    </Fragment>)
}   

export default ChangeLanguage