import { Fragment } from "react"
import ChangeLanguage from "../ChangeLanguage";
import { useTranslation } from "react-i18next";

const More = () => {

    const { t } = useTranslation()

    return <Fragment>
        <div className="d-flex justify-content-center px-3 flex-column">
            <p className="white_09_with_shadow font_15 mt-3 text-center">{ t("More_settings_p") }</p>
            <ChangeLanguage/>
        </div>
    </Fragment>
}

export default More