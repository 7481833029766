import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./store/ReduxStore";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { GzipBase64DecoderProvider } from './utils/GzipBase64DecoderContext';
import { WebSocketServiceProvider } from './utils/WebsocketContextSocketIO';
import { JSONParserProvider } from './utils/JsonParser';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <JSONParserProvider>
                <GzipBase64DecoderProvider>
                    <WebSocketServiceProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </WebSocketServiceProvider>
                </GzipBase64DecoderProvider>
            </JSONParserProvider>
        </Provider>
    </React.StrictMode>
);

serviceWorkerRegistration.register();
