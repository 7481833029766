import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client'
import { useDispatch } from 'react-redux'
import { uiActions } from '../store/uiSlice'
import { useGzipBase64Decoder } from './GzipBase64DecoderContext'
import { useJSONParser } from './JsonParser';
import { useTranslation } from 'react-i18next';
import AndroidInterface from "./AndroidInterface"


const WebSocketContext = createContext()
export const useWebSocket = () => { return useContext(WebSocketContext) }

export const WebSocketServiceProvider = ({children}) => {

    const dispatch = useDispatch()
    const [socket, setSocket] = useState()
    const { unzipAndDecode } = useGzipBase64Decoder()
    const { parseInitialData, parseMatchData, parseInitialValue } = useJSONParser()
    const { i18n } = useTranslation()

    const getUrlParams = () => {
        const searchParams = new URLSearchParams(window.location.search)
        let params = {}
        for (let param of searchParams) 
            params[param[0]] = param[1]
        return params
    }

    useEffect( () => {
        let ws_url = document.getElementById("wss_url").innerHTML;
        const newSocket = io(ws_url)
        setSocket(newSocket)
        newSocket.on("connect", () => { 
            AndroidInterface.logcat("Connected to Flask-SocketIO server")
            let payload = getUrlParams()
            payload.lang = i18n.language    
            newSocket.emit("get-initial-data", payload)
            AndroidInterface.logcat("get-initial-data was emitted")
        });

        newSocket.on('disconnect', (reason) => {
            AndroidInterface.logcat("on('disconnect') - reason:" + reason)
            if (reason === 'io server disconnect') {
                // The server has forced a disconnect
                socket.connect(); // Attempt to reconnect
            }
            // Other disconnection handling
        });

        newSocket.on("initial-data", (json_response) => {
            parseInitialValue(json_response) // der InitialValue wird separat direkt vom Backend überprüft!
            
            let gzippedBase64String = json_response["payload"]
            let json = unzipAndDecode(gzippedBase64String)
            AndroidInterface.logcat("initial-data received:\n" + JSON.stringify(json).substring(0, 250))
            // Parse das initiale JSON
            parseInitialData(json)
            dispatch(uiActions.setShowSplashscreen(false))
        })

        newSocket.on("main-data-stream", (json_response) => {
            let gzippedBase64String = json_response["payload"]
            let json = unzipAndDecode(gzippedBase64String)
            AndroidInterface.logcat("main-data-stream:\n" + JSON.stringify(json).substring(0, 250))
            parseMatchData(json)
        })

        newSocket.on("pong", (data) => { 
            AndroidInterface.logcat("Pong from server data:" + data)
        });

        return () => { 
            AndroidInterface.logcat("Diconnect is being executed...")
            newSocket.disconnect(); 
        }
    }, [dispatch, unzipAndDecode, parseInitialData]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect( () => {
        const intervalId = setInterval(() => { if(socket) socket.emit("ping") }, 1000)
        return () => { clearInterval(intervalId) }
    }, [socket])


    
    return (<WebSocketContext.Provider value={socket}>
                {children}
            </WebSocketContext.Provider>)
}
