import React, { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dataActions } from '../store/dataSlice';
import { uiActions } from '../store/uiSlice';
import MatchDataUtil from './MatchDataUtil';
import AndroidInterface from './AndroidInterface';

const JSONParserContext = createContext()
export const useJSONParser = () => useContext(JSONParserContext)

export const JSONParserProvider = ({children}) => {

    const dispatch = useDispatch()
    const [initialDataProcessed, setInitialDataProcessed] = useState(false)

    const parseInitialValue = (jsonPayload) => {
        let initialValue = "iv" in jsonPayload ? jsonPayload.iv : "0"
        if(AndroidInterface.getValueFromSharedPreferencesKey("iv") === "0" || AndroidInterface.getValueFromSharedPreferencesKey("iv") === undefined)
            AndroidInterface.setSharedPreferencesValue("iv", initialValue)
        dispatch( uiActions.setIsVerified("isv" in jsonPayload ? jsonPayload.isv : false))
    }

    const parseInitialData = (jsonPayload) => {
        if(initialDataProcessed) { return } else { setInitialDataProcessed(true) }

        if( "l" in jsonPayload ) {
            let leagueLookupDict = {}
            for( const leagueDict of jsonPayload["l"]) 
                leagueLookupDict[leagueDict["id"]] = leagueDict
            dispatch(dataActions.setLeagueLookup(leagueLookupDict))
        } 

        if( "c" in jsonPayload) {
            let clubLookupDict = {}
            for( const clubDict of jsonPayload["c"]) 
                clubLookupDict[clubDict["id"]] = clubDict
            dispatch(dataActions.setClubLookup(clubLookupDict))
        }
        parseMatchData(jsonPayload)
    }

    const parseMatchData = (jsonPayload) => {
        if( "md" in jsonPayload &&  "m" in jsonPayload["md"]) {
            const viewDataStructure = MatchDataUtil.createLeaguesViewDataStructure(jsonPayload["md"]["m"])
            dispatch(dataActions.setLeaguesViewDataStructure(viewDataStructure))
            dispatch(dataActions.setMatchLookup(jsonPayload["md"]["m"]))
        }
    }

    const JSONParserMethods = {
        parseInitialValue,
        parseInitialData,
        parseMatchData
    }

    return (
        <JSONParserContext.Provider value={JSONParserMethods}>
            {children}
        </JSONParserContext.Provider>
    )
}
