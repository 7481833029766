import { Fragment, useState, useEffect } from "react"
import LeagueRow from "./LeagueRow";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import Search from "./Search";
import BookieRow from "./BookieRow";

const LeaguesView = () => {

    const { t } = useTranslation();
    const [leagueRows, setLeagueRows] = useState([])
    const leagueLookupDict = useSelector(state => state.data.leagueLookup )
    const okay = useSelector(state => state.ui.isVerified )
    const leagueOrdering = JSON.parse( t("league_ordering") )
    const leaguesViewDataStructure = useSelector(state => state.data.leaguesViewDataStructure )
    
    useEffect(() => {
        let preLeagueRows = []
        for( const leagueId of leagueOrdering) 
            if( leagueId in leagueLookupDict) 
                preLeagueRows.push(<LeagueRow key={leagueId} leagueId={leagueId} matchDataStructure={leaguesViewDataStructure}/>)
        setLeagueRows(preLeagueRows)
    }, [leagueLookupDict, leaguesViewDataStructure]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<Fragment>
                <div className="LeaguesView_league_list_container_div">
                    <Search/>
                    { okay && <BookieRow/> }
                    {leagueRows}
                </div>
            </Fragment>)
}

export default LeaguesView