/*
    "CANCL" : "Canceled",
    "LIVE" : "Live",
    "ENDED" : "Final Result",
    "FT" : "Ended",
    "NS" : "Starting soon",
    "HT" : "Halftime",
    "POSTP" : "Postponed",
    "AET" : "Ended After Extratime",
    "ET" : "Extratime",
    "INT" : "Interuption",
    "BREAK" : "Pause",
    "DELAYED" : "Delayed",
    "FT_PEN" : "Penalty Shootout",
    "SUSP" : "Suspended",
    "PEN_LIVE" : "Penalty Shootout",
    "AWARDED" : "Winner awared", -> Ignorieren
    "ABAN" : "Rescheduled for later", -> Ignorieren

    Mögliche Beispiel Abläufe (live_match_recorder.matches)

    NS -> LIVE -> HT -> LIVE -> BREAK -> ET -> BREAK -> ET -> PEN_LIVE -> FT_PEN
    NS -> LIVE -> HT -> LIVE -> BREAK -> ET -> BREAK -> ET -> AET -> ENDED
    NS -> LIVE -> HT -> LIVE -> FT -> ENDED
    POSTP -> ENDED 
    CANCL -> ENDED
    NS -> LIVE -> INT -> HT -> LIVE -> FT -> ENDED oder NS -> LIVE -> HT -> LIVE -> INT -> ENDED 
    NS -> DELAYED -> NS -> LIVE -> HT -> LIVE -> FT -> ENDED 
    NS -> LIVE -> HT -> LIVE -> FT -> ABAN -> ENDED
    
    Problematische Verläufe und Probleme:
    1. Problem: Elfmeterschießen - Stand des Elfmeterschießen ist nicht bekannt -> Wo ist das in den Sportsmonk Daten zu finden?
    2. POSTP -> NS -> DELAYED -> ENDED  / POSTP -> ENDED  / CANCL -> ENDED --> diese Fälle erfordern eine Live-Match-Status-Historie um es korrekt ind er App darzustellen
    3. Wtf: SUSP -> NS -> FT -> ENDED (match_id: 2022041940146015) oder SUSP -> LIVE -> FT -> ENDED (match_id: 2022022346234007) -> Keine Ahnung was da passiert ist
    4. CANCL -> AWARDED -> ENDED (match_id: 2023040246217007) ????
    */

export const preMatchStates = ["NS"];
export const iregularPreMatchStates = ["POSTP", "DELAYED", "CANCL", "SUSP"];
export const validLiveStates = ["LIVE", "HT", "ET", "INT", "BREAK", "PEN_LIVE"];
export const terminalStates = ["ENDED", "FT", "AET", "FT_PEN"];
export const iregularTerminalStates = ["ABAN", "AWARDED"];

