import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MatchRow from './MatchRow';
import { useTranslation } from 'react-i18next';
import AndroidInterface from "../../utils/AndroidInterface"

const Search = () => {
    
    const formRef = useRef(null)
    const inputRef = useRef(null)
    const [formHeight, setFormHeight] = useState(0)
    const [formWidth, setFormWidth] = useState(0)
    const [closeIconPos, setCloseIconPos] = useState([0, 0, 0, 0])
    const [searchResultsArray, setSearchResultsArray] = useState([])
    const clubLookupDict = useSelector( state => state.data.clubLookup )
    const matchLookupDict = useSelector( state => state.data.matchLookup )
    const {t} = useTranslation()

    useEffect( () => {
        if( formRef.current ) 
            setFormHeight( formRef.current.clientHeight )

        if( inputRef.current) {
            setFormWidth( inputRef.current.clientWidth)
            
            const width = 0.9*inputRef.current.clientHeight
            const height = 0.9*inputRef.current.clientHeight

            const rect = inputRef.current.getBoundingClientRect();
            const x = rect.left + inputRef.current.clientWidth - width
            const y = rect.top + inputRef.current.clientHeight/2.0 - height/2.0
            
            setCloseIconPos( [x, y, width, height])
        }
    }, [])


    const searchClubs = (keyPressEvent) => {
        const searchTerm = keyPressEvent.target.value
        AndroidInterface.logcat("searchClubs: searchTerm: " + searchTerm)
        if(3 <= searchTerm.length) {
            const searchResults = []
            for( const clubId in clubLookupDict) {
                if( clubLookupDict[clubId].sn !== null && clubLookupDict[clubId].sn.toLowerCase().includes(searchTerm.toLowerCase() )) {
                    AndroidInterface.logcat("clubId:" + clubId + " for " + clubLookupDict[clubId].sn)
                    for( const matchId in matchLookupDict ) {
                        // eslint-disable-next-line eqeqeq
                        if( matchLookupDict[matchId]["c1"] == clubId || matchLookupDict[matchId]["c2"] == clubId) {
                            searchResults.push( <MatchRow key={matchId} autoComplete="off" matchId={matchId}/> )
                        }
                    }
                }
            }
            AndroidInterface.logcat("searchResults:" + JSON.stringify(searchResults))
            setSearchResultsArray(searchResults)
        }
    }

    return (
        <div className="d-flex py-2 px-3 justify-content-center">
            
            <Form ref={formRef} className="w-100">
                <Form.Group controlId="textInput">
                    <Form.Control ref={inputRef} onFocus={searchClubs} onChange={searchClubs} className="Search_input" type="text" placeholder={t("Search_placeholder_text")} />
                </Form.Group>
            </Form>

            { 1 < searchResultsArray.length && 
                <div className="Search_close_cross_div d-flex justify-content-center align-items-center pe-1" style= { { "left" : `${closeIconPos[0]}px`, "top" : `${closeIconPos[1]}px`, "width" : `${closeIconPos[2]}px`, "height" : `${closeIconPos[3]}px` } }>
                    <img onClick={ () => setSearchResultsArray([]) } className="Search_close_cross_img" src="images/close_cross_icon.svg" alt="Delete search results"/> 
                </div>
            }   
            
            { 1 < searchResultsArray.length && 
                <Fragment>
                    <div onClick={ () => setSearchResultsArray([]) } className="Search_background_overlay_div" style={ { "top" : `${formHeight+10}px` } }></div>
                    <div className="Search_results_overlay_div d-flex flex-column p-0 " style={ { "top" : `${formHeight+10}px`, "width" : `${formWidth}px` } }>
                    <div className="Search_results_container_div d-flex flex-column px-1 py-3 m-0">
                        {searchResultsArray}
                    </div>
                </div> 
                </Fragment>
            }
        </div>
    );
};

export default Search;
