import { Fragment } from "react"

const Specials = () => {
    return <Fragment>
        <div className="d-flex justify-content-center flex-column">
            <h1 className="white_09_with_shadow text-center mt-5">This tab will provide you with special informations on ongoing live matches:</h1>
            <p className="white_09_with_shadow text-center mt-3">Exclusive Content, Live Predictions</p>
            <p className="white_09_with_shadow font_15 text-center mt-5">Currently no live matches...</p>
        </div>
    </Fragment>
}

export default Specials