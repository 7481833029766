import { Fragment, useEffect, useState } from "react"
import MatchRow from "../leagues/MatchRow"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Search from "../leagues/Search"
import AndroidInterface from "../../utils/AndroidInterface"

const Favorites = () => {

    const [favorites, setFavorites] = useState([])
    const favoritesDict = useSelector(state => state.data.favorites)
    const matchesDict = useSelector(state => state.data.matchLookup)
    const { t } = useTranslation()

    useEffect( () => {
        const tmpRows = []
        for( const matchId in favoritesDict ) 
            if( matchId in matchesDict )
                tmpRows.push( <MatchRow key={matchId} matchId={matchId}/> )
        setFavorites(tmpRows)
    }, [favoritesDict, matchesDict])

    AndroidInterface.showToast("Hello from Favorites...")

    return <Fragment>
        <Search/>
        { 0 === favorites.length &&  
            <div className="d-flex w90 mx-auto p-0 px-3 m-0 justify-content-center align-items-center flex-column">
                <img className="Favorites_favorite_icon_filled_img p-0 m-0 mt-2" src="images/favorite_icon_filled.svg" alt="Favorites star symbol"/>
                <p className="white_09_with_shadow font_15 p-0 m-0 mt-3 text-center">{t("Favorites_add_your_favs_here")}</p>
                <p className="white_09_with_shadow p-0 m-0 mt-4 text-center">{t("Favorites_no_favs_yet")}</p>
                <p className="white_09_with_shadow p-0 m-0 mt-2 font_15 text-center">{t("Favorites_add_favs_now")}</p>
                <ol className="white_09_with_shadow p-0 m-0 mt-5">
                    <li>{t("Favorites_step_1")}</li>
                    <li>{t("Favorites_step_2")}<span><img className="p-0 m-0 ms-1" src="images/favorite_icon_filled.svg" alt="Favorites star symbol (small)"/></span></li>
                    <li>{t("Favorites_step_3")}</li>
                </ol>
            </div>
        }
        { 0 < favorites.length && 
            <div className="Favorites_list_container_div d-flex justify-content-center align-items-center p-0 m-0 flex-column">
                <img className="Favorites_favorite_icon_in_container_filled_img p-0 m-0" src="images/favorite_icon_filled.svg" alt="Favorites star symbol"/>
                <p className="white_09_with_shadow font_125 text-center">{ t("Favorites_your_favs") }</p>
                {favorites}
            </div>
        }
    </Fragment>

}

export default Favorites