import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import "../css/styles.css"
import { useTranslation } from 'react-i18next';

const SplashScreen = ({ isLoading }) => {
    const {t} = useTranslation()

    return (
        <Modal show={isLoading} size="sm" centered contentClassName="SplashScreen_transparent_modal_content">
            <Modal.Body> 
                <div className="text-center">
                    <div className="SplashScreen_vertical_flex">
                        <img src="/images/splashscreen_waiting_icon.png" alt={t("SplashScreen_loading")} className="SplashScreen_waiting_icon_img"/>
                        <Spinner animation="border" role="status" className="SplashScreen_spinner" />
                        <h1 className="SplashScreen_loading_data_p">{t("SplashScreen_loading")}</h1>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SplashScreen;
