import React, { createContext, useContext } from 'react';
import pako from 'pako';

const GzipBase64DecoderContext = createContext();
export const useGzipBase64Decoder = () => useContext(GzipBase64DecoderContext)

// Provider Component
export const GzipBase64DecoderProvider = ({ children }) => {

    const unzipAndDecode = (gzippedBase64String) => {
        // Decode Base64 string to binary data (Uint8Array)
        const gzippedBinaryString = atob(gzippedBase64String);
        
        const gzippedBinaryData = new Uint8Array(gzippedBinaryString.length);
        for (let i = 0; i < gzippedBinaryString.length; i++) 
            gzippedBinaryData[i] = gzippedBinaryString.charCodeAt(i);

        // Use Pako to deflate (ungzip)
        const deflatedData = pako.inflate(gzippedBinaryData);
        
        // Convert the deflated binary data back to a string
        const jsonString = new TextDecoder("utf-8").decode(deflatedData);
        
        // Parse JSON string if necessary
        return JSON.parse(jsonString);
    }


    // Add more utility functions as needed
    const gzipBase64DecoderMethods = {
        unzipAndDecode,
    };

    return (
        <GzipBase64DecoderContext.Provider value={gzipBase64DecoderMethods}>
            {children}
        </GzipBase64DecoderContext.Provider>
    );
};
