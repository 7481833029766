import DateTimeFormatter from "../../utils/DateTimeFormatter"
import { useTranslation } from 'react-i18next';

const DateTimeSeparatorRow = ({ dateTimeSeparatorDict }) => {
    
    const { i18n } = useTranslation()
    
    return (
        dateTimeSeparatorDict["id"] === "newDateSeparator" ?
            (<div className="DateTimeSeparatorRow_date_row_div d-flex justify-content-center align-items-center p-0 m-0">
                <p className="font_0875 white_09_with_shadow p-0 py-1 m-0 roboto500">{ DateTimeFormatter.dateFormat(dateTimeSeparatorDict["d"], i18n.language ) }</p>
            </div>)
        : 
            (<div className="DateTimeSeparatorRow_time_row_div d-flex justify-content-center align-items-center p-0 m-0">
                <p className="font_0875 white_09_with_shadow p-0 py-1 m-0 roboto500">{ DateTimeFormatter.timeFormat(dateTimeSeparatorDict["d"], i18n.language ) }</p>
            </div>)
    )
}

export default DateTimeSeparatorRow