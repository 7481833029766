const createLeaguesViewDataStructure = ( rawMatchDataJSONDict ) => {
    let matchesByLeagueId = {}
    for(const matchId in rawMatchDataJSONDict) {
        let matchDict = rawMatchDataJSONDict[matchId]
        let leagueId = matchDict["l"]
        matchDict["d"] = new Date( matchDict["d"].replace(" ", "T" ) + "Z")
        leagueId in matchesByLeagueId ? matchesByLeagueId[leagueId].push(matchDict) : matchesByLeagueId[leagueId] = [matchDict]
    }
    
    for(const leagueId in matchesByLeagueId) {
        matchesByLeagueId[leagueId].sort(( matchDictA, matchDictB) => matchDictA["d"] - matchDictB["d"]);

        let refDateTime = matchesByLeagueId[leagueId][0]["d"]
        let tmpMatchDictArray = [{ "id" : "newDateSeparator", "d" : refDateTime.toISOString() }]
        tmpMatchDictArray.push({ "id" : "newTimeSeparator", "d" : refDateTime.toISOString() })

        for( let i = 0; i < matchesByLeagueId[leagueId].length; ++i ) {
            let iterRefDateTime = matchesByLeagueId[leagueId][i]["d"]
            if(refDateTime.getTime() !== iterRefDateTime.getTime()) {
                // Same date but different time
                if( refDateTime.getFullYear() === iterRefDateTime.getFullYear() && refDateTime.getMonth() === iterRefDateTime.getMonth() && refDateTime.getDate() === iterRefDateTime.getDate() ) {
                    tmpMatchDictArray.push({ "id" : "newTimeSeparator", "d" : iterRefDateTime.toISOString() })
                } else {
                    tmpMatchDictArray.push({ "id" : "newDateSeparator", "d" : iterRefDateTime.toISOString() })
                    tmpMatchDictArray.push({ "id" : "newTimeSeparator", "d" : iterRefDateTime.toISOString() })
                }
                refDateTime = iterRefDateTime
            }
            // Für Redux Store muss das Date Object wieder serialisiert werden, also in einen String verwandelt werden
            matchesByLeagueId[leagueId][i]["d"] = matchesByLeagueId[leagueId][i]["d"].toISOString()
            tmpMatchDictArray.push(matchesByLeagueId[leagueId][i])
        }

        matchesByLeagueId[leagueId] = tmpMatchDictArray
    }

    return matchesByLeagueId
}


const MatchDataUtil = {
    createLeaguesViewDataStructure,
};


export default MatchDataUtil