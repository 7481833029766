import React, { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import MatchRow from "../leagues/MatchRow"
import DateTimeFormatter from "../../utils/DateTimeFormatter"
import { useTranslation } from "react-i18next"

const MatchDetails = React.forwardRef( ( {className, setPushDetails, matchId }, ref) => {

    const matchLookup = useSelector(state => state.data.matchLookup)
    const leagueLookup = useSelector(state => state.data.leagueLookup)
    const [matchDict, setMatchDict] = useState({"d" : "2024-01-25"} )
    const [leagueDict, setLeagueDict] = useState({"l" : 0})
    const background_img = `url(${window.baseURL}images/main_background.jpg)`;
    const { i18n } = useTranslation()

    useEffect( () => {
        if( matchLookup[matchId] !== undefined )
            setMatchDict(matchLookup[matchId])
        if( matchLookup[matchId] !== undefined && leagueLookup["" + matchLookup[matchId].l] !== undefined) 
            setLeagueDict(leagueLookup["" + matchLookup[matchId].l])
    }, [matchLookup, leagueLookup, matchId])

    return (
        <Fragment>
            <div ref={ref} className={`MatchDetails_div ${className}`} style= { {"backgroundImage" : background_img } }>
                <div className="d-flex w-100 flex-column scrollable_div">
                    <div className="d-flex w-100 justify-content-start ">
                        <img className="MatchDetails_navigate_back_img" onClick={ () => { setPushDetails(false) } } src="images/navigate_next.svg" alt="Go back button" />
                        <div className="d-flex w-100 justify-content-center align-items-center my-3">
                            <img className="p-0 m-0 me-2 MatchDetails_league_logo_img" src={`images/league_icons/${leagueDict.id}.png`} alt={`Logo of ${leagueDict.n}`}/>
                            <p className="p-0 m-0 ms-2 white_09_with_shadow font_125">{leagueDict.n}</p>
                        </div>
                        <div className="vw10_spacer"></div>
                    </div>
                    <div className="flex-grow-1 d-flex justify-content-center align-items-center mb-3">
                        <p className="white_09_with_shadow p-0 pe-2 m-0 font_125">{ DateTimeFormatter.dateFormat( matchDict.d, i18n.language ) }</p>
                        <p className="white_09_with_shadow p-0 ps-2 m-0 font_125">{ DateTimeFormatter.timeFormat( matchDict.d, i18n.language ) }</p>
                    </div>
                    <MatchRow matchId={matchId} onClickOpenDetails={false}/>
                    <p className="text-center p-0 m-0 mt-5 font_125 white_09_with_shadow">Live Infos comming when match started.</p>
                </div>
            </div>
        </Fragment>)
})

export default MatchDetails