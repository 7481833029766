import { useState } from "react"

const ClubLogo = ({ clubId, divClassName, imgClassName }) => {

    const [isAvailable, setIsAvailable] = useState(true)
    const imagePath = `images/club_icons/${clubId}.png`

    return (<div className={ divClassName ? divClassName : "" }>
        { isAvailable && <img className={ imgClassName ? imgClassName : "ClubLogo_logo_img" } src={imagePath} onLoad={ () => setIsAvailable(true)} onError={ () => setIsAvailable(false)} alt=""/>}
        { !isAvailable && <img className={ imgClassName ? imgClassName : "ClubLogo_logo_img" } src="images/generic_club_logo.png" alt=""/> }
    </div>)
}

export default ClubLogo