class AndroidInterface {

        static setSharedPreferencesValue = (key, value) => {
            if (typeof window.Android !== 'undefined' && window.Android.setSharedPreferencesKeyValue) 
                window.Android.setSharedPreferencesKeyValue(key, value)
        }

        static removeFromSharedPreferencesKey = (key) => {
            if (typeof window.Android !== 'undefined' && window.Android.removeFromSharedPreferencesKey) 
                window.Android.removeFromSharedPreferencesKey(key)
        }

        static getValueFromSharedPreferencesKey = (key) => {
            if (typeof window.Android !== 'undefined' && window.Android.getValueFromSharedPreferencesKey) 
                return window.Android.getValueFromSharedPreferencesKey(key)
        }

        static logcat = (msg) => {
            if (typeof window.Android !== 'undefined' && window.Android.logcat) 
                window.Android.logcat(msg)
            else
                console.log(msg)
        }

        static showToast = (msg) => {
            // Check if 'Android' is defined to avoid build and runtime errors in non-Android environments
            if (typeof window.Android !== 'undefined' && window.Android.showToast) {
                // Call the Kotlin function if the Android interface is available
                window.Android.showToast(msg);
            } else {
                // Fallback or alternative action for non-Android environments
                console.log("Android interface not available.");
            }
        }
}

export default AndroidInterface