import React, { Fragment, useEffect } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import LeaguesView from "./ui/leagues/LeaguesView";
import SplashScreen from "./ui/SplashScreen";
import "./css/styles.css"
import { useDispatch, useSelector } from "react-redux";
import NavigationBar from "./ui/NavigationBar";
import Favorites from "./ui/favorites/Favorites";
import Specials from "./ui/specials/Specials";
import More from "./ui/more/More";
import { dataActions } from "./store/dataSlice";

const App = () => {

    window.baseURL = document.getElementById("url").innerHTML;
    const showSplashscreen = useSelector(state => state.ui.showSplashScreen)
    const background_img = `url(${window.baseURL}images/main_background.jpg)`;

    /* Muss initial einmal in den Redux-Store geladen werden. */
    const dispatch = useDispatch()
    useEffect( () => {
        const favoritesDict = JSON.parse(localStorage.getItem('favorites')) || {}
        dispatch(dataActions.setFavorites(favoritesDict))
    }, [dispatch])
    

    return (
        <Fragment>
            <div className="fullscreen-background" style={{ backgroundImage: background_img }}></div>
            <NavigationBar />
            <SplashScreen isLoading={showSplashscreen}/>
            { !showSplashscreen && 
                <Routes>
                    <Route path="/" element={<LeaguesView/>}/>
                    <Route path="/favorites" element={<Favorites/>}/>
                    <Route path="/specials" element={<Specials/> }/>
                    <Route path="/more" element={<More/> }/>
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
            }
        </Fragment>);
}

export default App;
