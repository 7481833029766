import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom'


const NavigationBar = () => {

    const [iconSize, setIconSize] = useState([0, 0])
    const divRef = useRef()
    const { t } = useTranslation()
    const [activeBtn, setActiveBtn] = useState(1)
    const navigate = useNavigate()


    useEffect( () => {
        if(divRef.current) 
            setIconSize([0.45*divRef.current.clientHeight, 0.45*divRef.current.clientHeight])
    }, [])

    const navigateTab = (tabIdx) => {
        setActiveBtn(tabIdx)
        switch(tabIdx) {
            case 1: navigate('/')
                break;
            case 2: navigate('/favorites')
                break;
            case 3: navigate('/specials')
                break;
            case 4: navigate('/more')
                break;
            default: navigate('/')
                break;
        }
        
    }

    return (<div ref={divRef} className="NavigationBar d-flex justify-content-around align-items-center p-0 m-0">
        <div onClick={ () => navigateTab(1) } className={ "d-flex justify-content-around align-items-center p-0 m-0 flex-column " +  (activeBtn === 1 ? "" : " opacity_075") }>
            <img className="mx-2" style={ { "height" : `${iconSize[0]}px`} } src="images/navigation_icon_live.png" alt="* Overview of all matches *"/>
            <p className="p-0  pt-1 m-0 white roboto300 font_095">{t("NavigationBar_live")}</p>
        </div>
        <div onClick={ () => navigateTab(2) } className={"d-flex justify-content-around align-items-center p-0 m-0 flex-column" +  (activeBtn === 2 ? "" : " opacity_075") }>
            <img className="mx-2" style={ { "height" : `${iconSize[0]}px`} } src="images/navigation_icon_watch_list.png" alt="* Overview of all matches *"/>
            <p className="p-0  pt-1 m-0 white roboto300 font_095">{t("NavigationBar_favorites")}</p>
        </div>
        <div onClick={ () => navigateTab(3) } className={" d-flex justify-content-around align-items-center p-0 m-0 flex-column" +  (activeBtn === 3 ? "" : " opacity_075") }>
            <img className="mx-2" style={ { "height" : `${iconSize[0]}px`} } src="images/navigation_icon_specials.png" alt="* Overview of all matches *"/>
            <p className="p-0 pt-1 m-0 white roboto300 font_095">{t("NavigationBar_specials")}</p>
        </div>
        <div onClick={ () => navigateTab(4) } className={"d-flex justify-content-around align-items-center p-0 m-0 flex-column" +  (activeBtn === 4 ? "" : " opacity_075") }>
            <img className="mx-2" style={ { "height" : `${iconSize[0]}px`} } src="images/navigation_icon_more.png" alt="* Overview of all matches *"/>
            <p className="p-0  pt-1 m-0 white roboto300 font_095">{t("NavigationBar_more")}</p>
        </div>
    </div>)

}

export default NavigationBar