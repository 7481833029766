import { useState } from "react"
import { Collapse } from "react-bootstrap"
import { useSelector } from "react-redux";
import MatchRow from "./MatchRow";
import DateTimeSeparatorRow from "./DateTimeSeparatorRow";
import { validLiveStates } from "../../utils/MatchStates"

const LeagueRow = ({ leagueId, matchDataStructure }) => {

    const [isExpanded, setIsExpanded] = useState(false)
    const leagueLookupDict = useSelector(state => state.data.leagueLookup )

    const getMatchRows = () => {
        let matchRows = []
        let rowKeyIdCounter = leagueId
        if( leagueId in matchDataStructure) {
            for(const rowDict of matchDataStructure[leagueId]) {
                if(rowDict["id"] === "newTimeSeparator" || rowDict["id"] === "newDateSeparator" )
                    matchRows.push(<DateTimeSeparatorRow key={rowKeyIdCounter} dateTimeSeparatorDict={rowDict}/>)
                else 
                    matchRows.push(<MatchRow key={rowKeyIdCounter} matchId={rowDict.id} onClickOpenDetails={true}/>)
                rowKeyIdCounter++
            }
        }
        return matchRows
    }

    const getMatchesCount = () => {
        if(matchDataStructure[leagueId]) {
            let preMatchCounter = 0
            let liveMatchCounter = 0
            for( const rowDict of matchDataStructure[leagueId]) {
                if(rowDict["id"] !== "newTimeSeparator" && rowDict["id"] !== "newDateSeparator" && validLiveStates.includes(rowDict.ms) ) {
                    liveMatchCounter++
                } else if(rowDict["id"] !== "newTimeSeparator" && rowDict["id"] !== "newDateSeparator") {
                    preMatchCounter++
                }
            }
            if( 0 < liveMatchCounter)
                return `(${preMatchCounter}, ${liveMatchCounter})`
            else 
                return `(${preMatchCounter})`
        } else
            return "(0)"
    }

    return (
        <div className="d-flex w-100 justify-content-center align-items-center p-0 m-0 flex-column">
            <div onClick={ () => setIsExpanded(!isExpanded) } className="LeagueRow_header_div d-flex w-100 justify-content-start align-items-center px-0 py-1 m-0">
                <div className="LeagueRow_league_icon_name_div d-flex justify-content-center align-items-center p-0 m-0">
                    <img className="LeagueRow_league_icon_img me-3" src={ `images/league_icons/${leagueLookupDict[leagueId].id}.png` } alt=""/>
                    <p className="white_09_with_shadow m-0 ms-3 p-0 roboto500">{leagueLookupDict[leagueId].n} {getMatchesCount()}</p>
                </div>
                <img src="images/expand_chevron_shadowed.svg" className={"me-2 " + (isExpanded ? 'supportcollapse_icon_expand_css_animation' : 'supportcollapse_icon_collapse_css_animation')} alt=""/>
            </div>
            <Collapse in={isExpanded}>
                <div className="w-100 p-0 m-0">
                    <div className="d-flex w-100 p-0 m-0 flex-column">
                        {getMatchRows()}
                    </div>
                </div>
            </Collapse>
        </div>
    )

}

export default LeagueRow