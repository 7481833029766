import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useRef, useState } from "react"
import { Transition } from "react-transition-group"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import AndroidInterface from "../../utils/AndroidInterface"
import { dataActions } from "../../store/dataSlice"
import ClubLogo from "./ClubLogo"
import MatchDetails from "../details/MatchDetails"
import { preMatchStates,
         iregularPreMatchStates,
         validLiveStates,
         terminalStates,
         iregularTerminalStates } from "../../utils/MatchStates"



const MatchRow = ( {matchId, onClickOpenDetails /* Triggert ob MatchDetails aufgerufen werden kann. false in LeagueRow.js und true in MatchDetails.js */} ) => {

    const nodeRef = useRef(null)
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const matchLookup = useSelector(state => state.data.matchLookup)
    const [matchDict, setMatchDict] = useState({"id":2024012851307111,"l":11,"c1":513,"c2":71,"d":"2024-01-28T14:15:00.000Z"})
    useEffect( () => { setMatchDict(matchLookup[matchId]) }, [matchId, matchLookup])

    const [matchStatus, setMatchStatus] = useState("pre-match")
    const clubLookupDict = useSelector(state => state.data.clubLookup )
    const [pushDetails, setPushDetails] = useState(false)
    const [isRegisteredForMatchDetailsFromServer, setIsRegisteredForMatchDetailsFromServer] = useState(false)

    const favoritesDict = useSelector(state => state.data.favorites)
    const [isFavorite, setIsFavorite] = useState(false)
    useEffect( () => { 
        if( matchDict && "id" in matchDict)
            setIsFavorite(matchDict["id"] in favoritesDict) 
        else
            AndroidInterface.logcat("MatchRow.js -> useEffect -> matchDict: " + JSON.stringify(matchDict))
    }, [favoritesDict, matchDict])

    useEffect(() => {
        (matchDict && "ms" in matchDict && matchDict.ms !== "NS") ? setMatchStatus("in-play") : setMatchStatus("pre-match")

        if (!location.hash.includes("#details") && onClickOpenDetails) {
            setPushDetails(false);
            if( isRegisteredForMatchDetailsFromServer ) {
                AndroidInterface.logcat("2. DE-Register for detail update of " + (matchDict ? matchDict.id : matchDict) )
                setIsRegisteredForMatchDetailsFromServer(false)
            }
        }
    }, [location, isRegisteredForMatchDetailsFromServer, matchDict, onClickOpenDetails])

    const toggleIsFavorite = () => {
        //Achtung: Ist absichtlich verdreht...
        const deepCopyFavoritesDict = JSON.parse(JSON.stringify(favoritesDict))
        isFavorite ? delete deepCopyFavoritesDict[matchDict["id"]] : deepCopyFavoritesDict[matchDict["id"]] = matchDict
        dispatch(dataActions.setFavorites(deepCopyFavoritesDict))
    }


    const togglePushDetails = (setVisible) => {
        // Falls wir diese Component innerhalb der MatchDetails.js anzeigen wollen, dann soll diese Function hier nicht aufgerufen werden
        if(!onClickOpenDetails) 
            return

        // Falls in LeagueRow angezigt wird, soll man Details öffnen können
        if( setVisible ) {
            setPushDetails(true)
            navigate("#details")
            AndroidInterface.logcat("Register for detail update of " + ( matchDict ? matchDict.id : matchDict) )
            setIsRegisteredForMatchDetailsFromServer(true)
        } else {
            setPushDetails(false)
            navigate(-1)
            AndroidInterface.logcat("1. DE-Register for detail update of " + (matchDict ? matchDict.id : matchDict) )
            setIsRegisteredForMatchDetailsFromServer(false)
        }
    }



    const getMatchStatusLabelText = () => {
        let className = "p-0 m-0 roboto300 font_0875 white_09_with_shadow"
        if( !matchDict )
            return <p className={className}>N/A</p>

        if( preMatchStates.includes(matchDict.ms) ) {
            if( matchDict.ms === "NS" )
                return <p className={className}>{ t("MatchRow_NS") }</p>
        } 

        if( iregularPreMatchStates.includes(matchDict.ms) ) {
            if( matchDict.ms === "POSTP" )
                return <p>{ t("MatchRow_POSTP") }</p>
            if( matchDict.ms === "DELAYED" )
                return <p>{ t("MatchRow_DELAYED") }</p>
            if( matchDict.ms === "CANCL" )
                return <p>{ t("MatchRow_CANCL") }</p>
            if( matchDict.ms === "SUSP" )
                return <p>{ t("MatchRow_SUSP") }</p>
        }

        if( validLiveStates.includes(matchDict.ms) ) {
            if( matchDict.ms === "LIVE" && matchDict.htc === 0 ) 
                return <p className={className}>{ t("MatchRow_1st_halftime_text", { value : matchDict.mm} ) }</p>

            if( matchDict.ms === "HT" )
                return <p className={className}>{ t("MatchRow_HT") }</p>

            if( matchDict.ms === "LIVE" && matchDict.htc === 1 )
                return <p className={className}>{ t("MatchRow_2st_halftime_text", { value : matchDict.mm} ) }</p>

            if( matchDict.ms === "BREAK" )
                return <p className={className}>{ t("MatchRow_BREAK") }</p>
            
            if( matchDict.ms === "ET" )
                return <p className={className}>{ t("MatchRow_ET", { value : matchDict.mm} ) }</p>

            if( matchDict.ms === "INT" )
                return <p className={className}>{ t("MatchRow_INT") }</p>
            
            if( matchDict.ms === "PEN_LIVE" )
                return <p className={className}>{ t("MatchRow_PEN_LIVE") }</p>
        }
        
        if( terminalStates.includes(matchDict.ms) ) {
            if( matchDict.ms === "ENDED" )
                return <p className={className}>{ t("MatchRow_ENDED") }</p>
            if( matchDict.ms === "FT_PEN" )
                return <p className={className}>{ t("MatchRow_FT_PEN") }</p>
            if( matchDict.ms === "FT" )
                return <p className={className}>{ t("MatchRow_FT") }</p>
            if( matchDict.ms === "AET" )
                return <p className={className}>{ t("MatchRow_AET") }</p>
        }

        if( iregularTerminalStates.includes(matchDict.ms) ) {
            if( matchDict.ms === "ABAN" )
                return <p className={className}>{ t("MatchRow_ABAN") }</p>
            if( matchDict.ms === "AWARDED" )
                return <p className={className}>{ t("MatchRow_AWARDED") }</p>
        }

        return <p className="p-0 m-0 roboto300 font_0875 white_09_with_shadow">⚽️ - {matchDict.mm}'</p>
    }



    return (
        <Fragment>
            { onClickOpenDetails &&  
                <Transition in={pushDetails} timeout={200} nodeRef={nodeRef}>
                    {state => <MatchDetails ref={nodeRef} matchId={matchDict.id} setPushDetails={togglePushDetails} className={`MatchDetails_div_${state}`}/> }
                </Transition>
            }
            
            <div className="MatchRow_live_base_div d-flex justify-content-center align-items-stretch py-1 m-0">
                <div onClick={toggleIsFavorite} className="MatchRow_favorite_placeholder_div d-flex flex-column justify-content-center align-items-center p-0 m-0">
                    { !isFavorite && <img className="p-0 m-0" src="images/favorite_icon.svg" alt="Add to observation list to follow. Mark as favorite."/> }
                    { isFavorite && <img className="p-0 m-0" src="images/favorite_icon_filled.svg" alt="Add to observation list to follow. Mark as favorite."/> }
                </div>
                <div onClick={() => togglePushDetails(true) }  className="flex-grow-1 d-flex flex-column justify-content-center align-items-center p-0 m-0">
                    <ClubLogo clubId={matchDict ? matchDict.c1 : "-1"} divClassName="MatchRow_logo_div py-2 mt-2" imgClassName="MatchRow_logo_img"/>
                    <p className="text-center white_09_with_shadow roboto500 p-0 m-0 font_0875">{ matchDict ? clubLookupDict[matchDict.c1].n : "N/A" }</p>
                </div>
                <div onClick={() => togglePushDetails(true) } className="MatchFow_versus_div d-flex justify-content-center align-items-center">
                    { (matchStatus !== "in-play" ) &&
                        <p className="white_09_with_shadow font_15 roboto500 text-center p-0 px-3 m-0">⇌</p>
                    }
                </div>
                <div onClick={() => togglePushDetails(true) } className="flex-grow-1  d-flex flex-column justify-content-center align-items-center p-0 m-0">
                    <ClubLogo clubId={matchDict ? matchDict.c2 : "-1"} divClassName="MatchRow_logo_div py-2  mt-2" imgClassName="MatchRow_logo_img"/>
                    <p className="text-center white_09_with_shadow roboto500 p-0 m-0 font_0875">{ matchDict ? clubLookupDict[matchDict.c2].n : "N/A" }</p>
                </div>
                { onClickOpenDetails && 
                    <div onClick={() => togglePushDetails(true) } className="d-flex justify-content-center align-items-center p-0 m-0">
                        <img className="MatchRow_navigate_next_img" src="images/navigate_next.svg" alt="Select match and navigate to the details of this match"/>
                    </div>
                }
                
                { ( matchStatus === "in-play" ) && 
                    <div className="MatchRow_live_overlay_div d-flex justify-content-center align-items-stretch pt-1 m-0">
                        <div onClick={toggleIsFavorite} className="MatchRow_favorite_placeholder_div">
                            { /* Spacer, Placeholder der der Breites des Star-Icons darunter entspricht */}
                        </div>
                        
                        <div onClick={() => togglePushDetails(true) } className="MatchRow_live_container_div d-flex flex-column justify-content-center align-items-center p-0 m-0">
                            { validLiveStates.includes(matchDict ? matchDict.ms : "XXX") &&
                                <div className="MatchRow_live_in_play_div d-flex white_09_with_shadow">
                                    <p className="MatchRow_live_football_animation p-0 m-0 ms-2 me-2 font_0875">⚽️</p>
                                    <p className="pe-3 py-0 m-0 roboto300 font_0875">{ t("MatchRow_LIVE") }</p>
                                </div>
                            }
                            <div className="d-flex white_09_with_shadow">
                                { getMatchStatusLabelText() }
                            </div>
                            { (preMatchStates.includes(matchDict ? matchDict.ms : "XXX") || validLiveStates.includes(matchDict ? matchDict.ms : "XXX") || terminalStates.includes(matchDict ? matchDict.ms : "XXX") ) &&
                                <div className="d-flex white_09_with_shadow">
                                    <p className="p-0 m-0 roboto500 font_125 white_09_with_shadow">{`${matchDict ? matchDict.c1s : "?"} : ${matchDict ? matchDict.c2s : "?"}`}</p>
                                </div>
                            }
                        </div>
                        <div onClick={() => togglePushDetails(true) } className="MatchRow_live_placeholder_div">
                            { /* Spacer, Placeholder der der Breites des Pfeils darunter entspricht */}
                        </div>
                    </div>
                }
            </div>
        </Fragment>
        
    )
}

export default MatchRow